import React from "react";
import { Grid, Container } from "components/Design/Grid/Grid";
import { RenderBlock } from "./RenderBlock";
import {
  blocksWithoutTabContent,
  contactUsCard,
  isAnnouncementStrip,
  isBanner,
  isContactUsCard,
  isNavbar,
  isRecommendation,
} from "utils/renderBlocks";

export const SpaLayout = ({ blocks }) => {
  const contactUsBlock = contactUsCard(blocks);

  return (
    <div className="mb-24">
      <Container>
        <Grid>
          <div className="my-24 space-y-24 lg:col-span-7  lg:my-40 lg:space-y-40">
            {blocksWithoutTabContent(blocks).map((b) => {
              const skip =
                isNavbar(b) ||
                isBanner(b) ||
                isRecommendation(b) ||
                isContactUsCard(b) ||
                isAnnouncementStrip(b);

              if (!skip) {
                return (
                  <RenderBlock
                    key={`${b.__typename}:${b.id}`}
                    block={b}
                    blocks={blocks}
                  />
                );
              }
            })}
          </div>
          <div className="hidden lg:col-span-5 lg:ml-20 lg:block mt-60">
            {contactUsBlock && <RenderBlock block={contactUsBlock} />}
          </div>
        </Grid>
      </Container>
    </div>
  );
};
